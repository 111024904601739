<template>
  <div class="userAgreement">
    <div class="agreeCheckbox">
      <el-checkbox v-model="checked" @change="choose">
        <span v-if="locale == 'en-US'">
          I have read and agree to the
        </span>
        <span v-else>
          我已经阅读并同意
        </span>
      </el-checkbox>
    </div>
    <div class="agreeText">
      <span v-if="locale == 'en-US'">
        <el-button type="text" @click="dialogTableVisible = true"
          >Privacy Policy and User Agreement</el-button
        >
      </span>
      <span v-else>
        <el-button type="text" @click="dialogTableVisible = true"
          >隐私政策和用户协议</el-button
        >
      </span>
    </div>
    <el-dialog
      center
      :title="agreementInfo.title"
      :visible.sync="dialogTableVisible"
    >
      <div class="agreementInfo" v-loading="loading">
        <span v-html="agreementInfo.content"></span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" type="primary" @click="dialogTableVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  props: {
    msg: String
  },
  data() {
    return {
      checked: false,
      dialogTableVisible: false,
      agreementInfo: {},
      loading:true
    };
  },
  watch: {
    dialogTableVisible(newV, oldV) {
      if (newV) {
        if (Object.keys(this.agreementInfo).length === 0) {
          this.agreement();
        }
      }
    }
  },
  methods: {
    choose(flag) {
      console.log("是否同意隐私政策", flag);
      this.$emit("chooseAgreement", flag);
    },
    async agreement() {
      let res = await this.getpages("agreement");
      this.agreementInfo = res.pageInfo;
      this.loading = false;
    },
    toPage() {
      this.$router.push({ path: "/manual" });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vuex_color: #9b0000;
@mixin checkColor() {
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label,
  .el-checkbox {
    color: $vuex_color;
  }
  ::v-deep .el-checkbox__inner,
  .el-checkbox__inner:hover {
    border-color: $vuex_color;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $vuex_color;
    border-color: $vuex_color;
  }
}
@mixin btnColor() {
  .btn {
    background-color: $vuex_color;
    border-color: $vuex_color;
    width: 120px;
    border-radius: 5px;
  }
}
.userAgreement {
  padding: 20px 0 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  color: #838383;
  font-size: 12px;
  .agreeCheckbox {
    @include checkColor;
  }
  .agreeText {
    //   margin-left: 24px;
    cursor: pointer;
    font-size: 14px;
    color: #409eff;
  }
  .agreementInfo {
    // max-height: 55vh;
    height: 55vh;
    overflow: auto;
  }
  @include btnColor;
}
</style>
