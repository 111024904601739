<template>
  <div class="changePswd">
    <div class="changeBox">
      <el-form class="formBox" :model="formData" :rules="rules" ref="formData">
        <el-tabs class="typeTabs" v-model="activeType" @tab-click="handleClick">
          <el-tab-pane :label="i18nData.typephone" name="phone"></el-tab-pane>
          <el-tab-pane :label="i18nData.typeemail" name="email"></el-tab-pane>
        </el-tabs>
        <template v-if="activeType == 'phone'">
          <el-form-item class="formItem" prop="phone">
            <el-input
              class="input"
              v-model="formData.phone"
              :placeholder="i18nData.phonetips"
              ><template slot="prefix">+86</template></el-input
            >
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item class="formItem" prop="email">
            <el-input
              class="input"
              v-model="formData.email"
              :placeholder="i18nData.emailtips"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item class="formItem" prop="password">
          <el-input
            class="input"
            v-model="formData.password"
            :placeholder="i18nData.pswtips"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <userAgreement @chooseAgreement="chooseAgreement"></userAgreement>
        <el-form-item class="formItem">
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitForm('formData')"
            >{{ i18nData.button }}</el-button
          >
        </el-form-item>
        <div class="toOther">
          <span @click="toPage(1)">{{ i18nData.forgetpw }}</span>

          <span @click="toPage(0)">{{ i18nData.signup }}</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { loginfunc,checkAgreement } from "./../common/top.mixin.js";
import userAgreement from "./../components/userAgreement.vue";

export default {
  name: "login",
  mixins: [loginfunc,checkAgreement],
  components: {
    userAgreement
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        //  console.log("rule里调用this",this.i18nData)
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.formData.password !== "") {
        //   this.$refs.formData.validateField("password");
        // }
        callback();
      }
    };
    return {
      activeType: "phone",
      formData: {
        phone: "",
        email: "",
        password: ""
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        password: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  created() {
    this.$store.state.activeIndex = "";
    this.$store.state.myActiveIndex = this.$route.name;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.$refs["formData"].clearValidate();
    },
    submitForm(formName) {
      // 判断是否勾选协议
      if(!this.checkChoose()){
        return
      }
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            account:
              this.activeType == "phone"
                ? this.formData.phone
                : this.formData.email,
            password: this.formData.password
          };
          let res = await this.$api.https.goLogin(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$store.state.vuex_token = res.data.token;
          this.success(2);
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "登录验证失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    toPage(index) {
      let pageArr = ["/register", "/forgetpwd"];
      this.$router.replace({ path: pageArr[index] });
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #9b0000;
.changePswd {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 80px 0 60px 40px;
  .changeBox {
    width: 450px;
    box-sizing: border-box;
    padding: 60px 0;
    border: 1px solid #dcdcdc99;
    .formBox {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .formItem {
        // margin-bottom: 5px;
        .input {
          width: 280px;
        }
        ::v-deep.el-input__inner {
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }
      }
      .submitBtn {
        margin-top: 20px;
        background-color: $vue_color;
        border-color: $vue_color;
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 5px;
      }
      .toOther {
        font-size: 13px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        span {
          margin-left: 10px;
          color: $vue_color;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
